import { makeStyles, Typography } from "@material-ui/core"
import { useRouter } from "next/router"
import { FunctionComponent } from "react"
import { FlexBox } from "../components/FlexBox"
import { BaseBackgroundLayout } from "../layouts/baseBackground"

const useStyles = makeStyles({
  pre: {
    maxWidth: "90%",
    overflow: "auto",
  },
})

export const NotFound: FunctionComponent = () => {
  const { query } = useRouter()
  const classes = useStyles()

  return (
    <BaseBackgroundLayout>
      <FlexBox
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        px={4}
        py={4}
      >
        <Typography variant="h4" style={{ color: "#184B70" }} align="center">
          Whoops, this page does not exist.
        </Typography>
      </FlexBox>
    </BaseBackgroundLayout>
  )
}

export default NotFound
